import React from 'react'
import clsx from 'clsx'
import { useField } from 'formik'
import SelectSearch from 'react-select-search/dist/cjs'
import Fuse from 'fuse.js'

import countryPhoneCodes from '@/utils/enums/country-calling-codes'

/* styling */
import styles from './phone.module.css'

interface Props {
  label?: string
  name: string
  className?: string
  inputClass?: string
  labelClass?: string
  placeholder?: string
  required?: boolean
}

export const phoneExtensions = countryPhoneCodes.map(code => ({
  name: code.dial_code,
  value: code.dial_code,
  countryName: code.name
}))

const PhoneField = ({
  label,
  name,
  className,
  inputClass,
  labelClass,
  placeholder,
  required
}: Props): JSX.Element => {
  const [field, meta, helpers] = useField<{
    extension: string
    number: string
  }>(name)

  const filterOptions = () => {
    const fuse = new Fuse(phoneExtensions, {
      keys: ['name', 'countryName']
    })

    return (value: string) => {
      if (!value.length) return phoneExtensions

      return fuse.search(value, { limit: 5 })
    }
  }

  return (
    <div className={clsx('input__container', className)}>
      {label && (
        <label
          className={clsx(
            labelClass,
            'block  | text-gray-80 font-medium  |  mb-2'
          )}
          style={{ fontSize: 13 }}
          htmlFor={`form-${name}`}
        >
          <span>{label}</span>
          {required && <span className="text-orange-50">&nbsp;*</span>}
        </label>
      )}
      <div className="flex">
        <SelectSearch
          id={`form-${name}`}
          name="x"
          value={field.value.extension}
          options={phoneExtensions}
          className={(key: string) => styles[`select_${key}`]}
          onChange={(value: string) =>
            helpers.setValue({ ...field.value, extension: value })
          }
          search={true}
          filterOptions={filterOptions}
        />
        <input
          {...field}
          id={`form-${name}`}
          placeholder={placeholder}
          value={field.value.number}
          onChange={e =>
            helpers.setValue({ ...field.value, number: e.target.value })
          }
          type="tel"
          className={clsx(
            'border border-gray-40 rounded-l-none rounded-lg  |  w-full h-12  |  px-4',
            inputClass,
            styles.input
          )}
        />
      </div>

      {meta.touched && meta.error ? (
        <div className="text-small font-medium text-orange-50  |  mt-2">
          {(meta.error as { number?: string })?.number}
        </div>
      ) : null}
    </div>
  )
}

export default PhoneField
