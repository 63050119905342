const timezoneToCountry = {
  unitedStates: {
    timeZones: [
      'America/New_York',
      'America/Detroit',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Indiana/Indianapolis',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
      'America/Chicago',
      'America/Indiana/Tell_City',
      'America/Indiana/Knox',
      'America/Menominee',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/North_Dakota/Beulah',
      'America/Denver',
      'America/Boise',
      'America/Phoenix',
      'America/Los_Angeles',
      'America/Anchorage',
      'America/Juneau',
      'America/Sitka',
      'America/Metlakatla',
      'America/Yakutat',
      'America/Nome',
      'America/Adak',
      'Pacific/Honolulu'
    ],
    phoneCode: '+1'
  },
  india: {
    timeZones: ['Asia/Calcutta', 'Asia/Kolkata'],
    phoneCode: '+91'
  }
}

export const getDefaultPhoneCode = (defaultCode = '+91') => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  for (const key in timezoneToCountry) {
    const keyWithType = key as keyof typeof timezoneToCountry
    if (timezoneToCountry[keyWithType].timeZones.includes(userTimeZone)) {
      defaultCode = timezoneToCountry[keyWithType].phoneCode
      return defaultCode
    }
  }
  return defaultCode
}
